.bg-wide {
    height: auto;
    min-height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0 !important;
}

body, input {
    font-size: 16px;
}

@media print {
    body,input {
        font-size: 12px;
    }
}

/*
.ms-font {
    //font-family: 'Montserrat', sans-serif;
}
*/
.row-pt-3 {
    padding-top: 15px;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
.text-white {
    font-family: 'Montserrat', sans-serif;
    color: white;
}
.input-group {
    display: block;
    margin-bottom: 10px;
}
input {
    width: 100%;
}

.bg-grad {
    background: linear-gradient(331deg, #2d4557, #20303d, #344f63);
    background-size: 600% 600%;

    -webkit-animation: AnimationName 18s ease infinite;
    -moz-animation: AnimationName 18s ease infinite;
    animation: AnimationName 18s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:13% 0%}
    50%{background-position:88% 100%}
    100%{background-position:13% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:13% 0%}
    50%{background-position:88% 100%}
    100%{background-position:13% 0%}
}
@keyframes AnimationName {
    0%{background-position:13% 0%}
    50%{background-position:88% 100%}
    100%{background-position:13% 0%}
}

.main-container {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
}
.form-padding {
    padding-left: 15px;
    padding-right: 15px;
}

.loader {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.75);
    position: fixed;
    text-align: center;
    color: white;
    z-index: 9999;

    .spin {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: center;
        width: 100%;
    }
}

.input-group {
    padding-left: 10px;
    padding-right: 10px;
}